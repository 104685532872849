.edit-form-container .inputfield{
    display: flex;
    gap: 1rem;
    align-items: baseline;
    flex-wrap: wrap;
    width: 50%;
    padding: 1.5rem 2rem;
    border: 1px solid #b3b3b3;
    width: 100%;
}
.left .inputfield input[type="text"], 
.left .inputfield textarea  {
    border: none;
    border-bottom: 2px solid var(--oro);
    text-align: start;
    width: 100%;
    word-break: break-word;
    padding: .5rem 1rem 0 1rem;
    resize: none;
}
.left .inputfield input[type="text"]:disabled, 
.left .inputfield textarea:disabled {
    border-bottom: 1px solid var(--azul);
}
.inputfield .description{
    font-size: .7rem;
}

.edit-form-container{
    display: flex;
    gap: 2rem;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: row;
}
.edit-form-container.container.pdf {
    /* flex-direction: column; */
}
.date-info{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.date-info .info{
    display: flex;
    flex-direction: row;
    align-items: baseline;
    gap: 1rem;
}
.date-info .info *{
    margin: 0;
}

.edit-form-container .back{
    margin-top: 2rem;
}
.edit-form-container i{
    color: var(--oro);
}
.input {
    display: flex;
    width: 100%;
    justify-content: space-between;
}
aside.actions-admin {
    /* position: sticky; */
    /* top: 82%; */
    display: flex;
    gap: 2rem;
    flex-direction: row;
    justify-content: center;
}
aside.actions-admin .btn-primary.rechazar{
    background-color: var(--error);
}
.files ul {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    align-items: flex-start;
}
.files ul li.active{
    color: var(--oro);
    border-bottom: 1px solid var(--oro);
}
.inputfield.activity-admin,
.activity-by-sector {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: .5rem;
    background: var(--azul-active);
}
.inputfield.activity-admin .question,
.activity-by-sector,
.activity-by-sector .question,
.inputfield.activity-admin select,
.inputfield.activity-admin input{
    width: 100%;
}
.inputfield.activity-admin select,
.inputfield.activity-admin input{
    padding: .5rem 1rem;
    border: 2px solid #afafaf;
}
.inputfield.activity-admin select::-moz-focus-inner,
.inputfield.activity-admin input::-moz-focus-inner{
    padding: .5rem 1rem;
    border: 2px solid var(--oro);
}


.file-input::-webkit-file-upload-button {
    visibility: hidden;
  }
.file-input::-webkit-input-placeholder {
    visibility: hidden;
  }
.file-input::before {
    content: attr(data-before);
    display: inline-block;
    background: var(--oro);
    color: white;
    border: 2px solid transparent;
    border-radius: 3px;
    padding: 1rem;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    font-size: 10pt;
    /* position: absolute; */
    /* left: 50%; */
    transform: translateX(30%);
    z-index: 2;
    /* width: 50%; */
    /* text-align: center; */
    transition: all .5s ease;
    opacity: 1;
    box-shadow: 2px 2px 1px 0px #24180785;
  }
  .file-input:hover::before {
    box-shadow: 1px 2px 1px 1px #241807e5;
  }
  .file-input:active::before {
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
  }
  .files-actions {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: baseline;
}
.form-files, .delete-files {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.files-actions form{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: .5rem;
}
.files-actions form .btn{
    align-self: center;
}
.files-actions .file-input::before {
    transform: translateX(0);
    width: 100%;
    text-align: center;
}
.files-actions .file-input:hover::before {
    filter: brightness(1.3);
}
section.left {
    flex: 1;
}
section.right.files {
    flex: 1.5;
    position: -webkit-sticky;
    flex: 1.5;
    position: sticky;
    top: 100px;
    bottom: 0;
    align-self: flex-start;
}

span.error {
    bottom: 2.5rem;
    color: red;
    font-size: small;
}

input textarea{
    text-transform: uppercase;
}
.column{
    flex-direction: column;
  }
.inputfield.inline-flex{
    width: 30%;
  }
  .files-header{
    display: flex;
  }
  .files-view{
   width: 100%;
  }