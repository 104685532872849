.form-container {
    width: 90%;
    background-color: #FBFBFB;
    margin: 0 auto;
    border-radius: .5rem;
    min-height: 70vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    padding: 3rem;
}
.form {
    width: 100%;
}
form#dynamic-form {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.form-header {
    display: flex;
    align-items: baseline;
    justify-content: space-around;
    margin: 2rem;
    gap: 2rem;
}
.form-description {
    padding-left: .5rem;
    border-left: 1px solid;
    margin: 1rem 0 3rem 0;
}
div#dynamic-form {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-direction: column;
}
div#dynamic-form .btns{
    align-self: center;
    display: flex;
    flex-direction: row;
    gap: 2rem;
}

form input, textarea, select{
    text-transform: uppercase;
    padding: .5rem 1rem;
    border-radius: 0.3rem;
    /* outline: 0; */
    border: 2px solid #c7c7c7;
    accent-color:var(--azul);
}
form input[type=email], form input[type=password]{
    text-transform: none;
}
form input.error, textarea.error, select.error{
    border: 2px solid #e06464;
}
.msg-error{
    color: #e06464;
}

form input:focus-visible, textarea:focus-visible{
    border: 2px solid var(--azul);
    accent-color:var(--azul);
    transition: all .5s ease;
}


textarea{
    resize: none;
}
.question {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
    gap: .5rem;
    width: 100%;
}
form#sectionForm {
    max-width: 950px;
    margin: auto;
}
.question p{
    margin: 0;
}
.question span {
    font-size: small;
    font-family: system-ui;
}
.question  label, .question p {
    font-size: larger;
    font-weight: 400;
    font-family: system-ui;
    color: hsl(30deg 4% 20%);
}
.question .inputfield  label {
    font-size: smaller;
    font-weight: 400;
    font-family: system-ui;
}
.say-true{
    text-align: center;
}
.say-true .question{
    flex-direction: row;
}
.say-true .question label{
    font-size: smaller;
    text-align: justify;
}
form#form-start {
    line-height: 1.5rem;
}


