.dashboard-container {
    /* padding: 1rem 5vh; */
    display: flex;
    flex-direction: column;
    /* gap: 2rem; */
    /* min-height: 100vh; */
}
.dashboard-name {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    gap: .5rem;
    margin: auto;
}
.dashboard-name h3{
    color: var(--azul);
}
.dashboard-cards {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: 2rem 1rem;
    padding: 1rem 2rem;
}
.dashboard-actions {
    display: flex;
    justify-content: center;
    gap: 2rem;
    margin-bottom: 1rem;
}
.dashboard-actions .btn-container {
    position: relative;
}
.dashboard-actions .btn-container .notification {
    width: 15px;
    height: 15px;
    background: red;
    border-radius: 11rem;
    position: absolute;
    top: -5px;
    right: 0;
}
.dashboard-cards.hide{
    display: none;
}
.add-date {
    padding: 2rem 3rem;
    background: hsl(205deg 58% 75%);
    border-radius: 2rem;
    width: 70%;
    text-align: center;
    margin: auto;
}
.date-card {
    /* text-align: -webkit-left; */
    background: var(--azul-cards);
    padding: 2rem 3rem;
    border-radius: 1rem;
    box-shadow: 1px 2px 4px 0px #a6a6a6;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
    width: 100%;
    max-width: 1000px;
}
.date-card.add-new {
        justify-content: center;
        text-align: center;
        align-items: center;
}
.date-card.add-new .btn{
        align-self: center;
}
.date-card-text{
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: center;
    gap: 1rem;
}
.date-card-text.no-date {
    flex-direction: column;
}
.date-card-text.no-date :is(p, span) {
    font-size: small;
}
.date-card-text h3,.date-card-text p {
    margin: .2rem;
}
.date-card .actions{
    display: flex;
    flex-direction: row;
    gap: 1rem;
    place-self: self-end;
}
.cedula{
    position: absolute;
    top: -7px;
    left: 1rem;
    color: var(--oro);
    border-bottom: 1px solid;
    width: fit-content;
    font-size: large;
}

.btn-active{
    background-color: var(--azul-active);
    color: var(--azul);
    transition: all .4s ease;
}

.form-field {
    flex-direction: row;
    align-items: baseline;
    position: relative;
    /* height: 30px; */
}
.form-field input{
    padding: 0.5rem 1rem;
    border-radius: 1rem;
    border: 2px solid #b9b9b9;
    width: 100%;
    outline: none;
    outline-offset: 0;
}
.search-date .btn-primary{
    margin: 0;
    position: absolute;
    right: 0;
    padding: 0.6rem 1rem;
    height: 100%;
    border-radius: 0 1rem  1rem 0;
}
.status-container {
    position: absolute;
    bottom: 0;
    right: 1rem;
    display: flex;
    align-items: baseline;
    gap: .5rem;
}
.status-container .dot{
    width: 10px;
    height: 10px;
    border-radius: 2rem;
}
.status-container .dot.green{
    background-color: green;
}
.status-container .dot.red{
    background-color: red;
}

.process {
    width: 80%;
    display: flex;
    justify-content: space-between;
    margin: auto;
    position: relative;
    margin-bottom: 2rem;
}
.process::before {
   content: '';
   width: 100%;
   position: absolute;
   height: 1px;
   background: gray;
   top: 50%;
}
.step {
    width: 50px;
    background: gray;
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1/1;
    border-radius: 2rem;
    z-index: 2;
    color: white;
    position: relative;
}
.step span{
    position: absolute;
    bottom: -20px;
    color: var(--azul);
    font-size: small;
}
.step.completed {
    background-color: rgb(73, 138, 73);
}
.step.declinada {
    background-color: rgb(161, 74, 74);
}
.nl_logo{
    width: 95px;
}
.dashboard-header{
    position: fixed;
    width: 100%;
    padding: 1rem;
    text-align: center;
    box-shadow: 1px 1px 7px 4px #0000003d;
    background-color: tomato;
    color: white;
    top: 6rem;
    z-index: 100;
}